// eslint-disable-next-line @typescript-eslint/no-unused-vars
/*global chrome*/
import * as React from "react";
import { Suspense } from "react";
import * as ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Loader } from "@fluentui/react-northstar";
import "./teamsPage/assets/main.css";
import "./teamsPage/assets/tailwind.css";
import { HowToRedirect } from "./teamsPage/howTo/HowToRedirect";
const LandingPage = React.lazy(() => import("./landingPage/LandingPage"));
const TeamsPageRouter = React.lazy(() => import("./teamsPage/TeamsPageRouter"));
const TeamsPricesPage = React.lazy(
  () => import("./pages/stripe/prices/StripePricesPage")
);
const SuccessPaymentPage = React.lazy(
  () => import("./pages/stripe/SuccessPaymentPage")
);
const FailedPaymentPage = React.lazy(
  () => import("./pages/stripe/FailedPaymentPage")
);
const Demo = React.lazy(() => import("./pages/demo/DemoPlayPage"));

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<Loader />}>
      <Router>
        <Switch>
          <Route path="/landing" component={LandingPage} />
          <Route path="/payment/success" component={SuccessPaymentPage} />
          <Route path="/payment/failed" component={FailedPaymentPage} />
          <Route path="/payment/prices" component={TeamsPricesPage} />
          <Route path="/joyful" component={HowToRedirect} />
          <Route path="/confident" component={HowToRedirect} />
          <Route path="/energized" component={HowToRedirect} />
          <Route path="/centered" component={HowToRedirect} />
          <Route path="/demo" component={Demo} />
          <Route path="/share/*" component={HowToRedirect} />
          <Route path="/" component={TeamsPageRouter} />
        </Switch>
      </Router>
    </Suspense>
  </React.StrictMode>,
  document.getElementById("app")
);
